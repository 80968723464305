import React, { useEffect } from 'react'
import moment from 'moment'
import { useToasts } from 'react-toast-notifications'

import { P } from '../../../style/Typography.style'

import Flex from '../../layouts/Flex'
import Tags from '../../Tags'

import useFetch from '../../../hooks/useFetch'

const ReasonForRejection = ({ talent }) => {
  const { addToast } = useToasts()
  const { data, error } = useFetch(`${talent.id}/rejected`)

  useEffect(() => {
    if (error) {
      addToast(error?.response?.data?.message || 'Something went wrong.', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }, [error])
  return (
    <>
      <P color="#DC2929" weight="500" padding="0 0 0.25rem 0">
        REASON(S) FOR REJECTION:
      </P>

      {data?.resp?.interviewRejectionReasons?.length > 0 ? (
        <Flex gap="0.25rem" direction="column">
          {data?.resp?.interviewRejectionReasons.map((e) => (
            <Tags
              key={e.rejection_reason_id}
              status="listed"
              text={e.description}
              maxWidth="100%"
            />
          ))}
        </Flex>
      ) : (
        <P weight="500"> No rejection reason specified.</P>
      )}

      <P color="#DC2929" weight="500" padding="0.5rem 0 0.25rem 0">
        COMMENTS:
      </P>

      {data?.resp?.comments?.[0]?.message ? (
        <P padding="0 0 0.25rem 0">{data.resp.comments[0].message}</P>
      ) : (
        <P weight="500">No comments.</P>
      )}

      <P size="0.75rem">
        By {data?.resp?.rejectedBy} on{' '}
        {data &&
          data?.resp?.rejectedDate &&
          moment(data?.resp?.rejectedDate).format('DD/MM/YYYY')}{' '}
        • {data?.resp?.rejectedStage}{' '}
      </P>
    </>
  )
}

export default ReasonForRejection
